import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import LockIcon from "@mui/icons-material/Lock";
import RateReviewIcon from "@mui/icons-material/RateReview";

const items = [
  {
    icon: <LockIcon />,
    title: "Anonymous feedback",
    description:
      "All feedback on our platform is completely anonymous, ensuring that feedback givers feel confident in sharing their true thoughts without fear of judgment or exposure. ",
  },
  {
    icon: <RateReviewIcon />,
    title: "AI rephrasing",
    description:
      "Feedback seeker can request our AI technology to rephrase all submitted feedback, so even if someone knows your writing style, they won’t be able to identify you.",
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: "Summarized insights",
    description:
      "We understand that long, detailed feedback can be overwhelming. That’s why we provide a summarized version of all collected feedback, allowing the receiver to quickly grasp the key points and take action.",
  },
];

export default function KeyFeatures() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 6 },
        pb: { xs: 4, sm: 6 },
        paddingLeft: { xs: 2, sm: 6 },
        paddingRight: { xs: 2, sm: 6 },
        color: "white",
        bgcolor: "#03101D",
        borderRadius: { xs: 4, sm: 8 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "80%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            component="h2"
            variant="h2"
            fontWeight="600"
            gutterBottom
            sx={{ fontSize: { xs: "1.4rem", md: "1.6rem" } }}
          >
            What is Sincero?
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1, color: "grey.500" }}>
            Sincero is designed to provide honest, anonymous feedback while
            ensuring that both the feedback givers and receivers have a seamless
            and productive experience.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  flexWrap: "wrap",
                  color: "inherit",
                  p: 3,
                  minHeight: { lg: "220px" },
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                  backgroundColor: "#031221",
                  borderRadius: 8,
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "grey.400",
                    }}
                  >
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
