import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { Helmet } from "react-helmet";
import BrandLogo from "../brandLogo.jsx";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Fade,
  Rating,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

const BusinessAnswerPage = () => {
  const { requestId } = useParams();
  const [questionData, setQuestionData] = useState(null);
  const [answer, setAnswer] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch the question details when the page loads
  useEffect(() => {
    const fetchQuestionData = async () => {
      setLoading(true);
      const docRef = doc(db, "business_feedback_request", requestId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionData(docSnap.data());
      } else {
        console.log("No such document!");
      }

      setLoading(false);
    };

    fetchQuestionData();
  }, [requestId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (questionData?.need_rating && rating === 0) {
      setRatingError(true);
      return;
    } else {
      setRatingError(false);
    }

    try {
      const answerData = {
        answer: answer,
        rating: questionData?.need_rating ? rating : null,
        answer_time: new Date().toISOString(),
      };

      const docRef = doc(db, "feedback_answer", requestId);

      await setDoc(
        docRef,
        {
          answers: arrayUnion(answerData),
        },
        { merge: true }
      );

      setSubmitted(true);
      setAnswer("");
      setRating(0);
    } catch (error) {
      console.error("Error submitting answer: ", error);
    }
  };

  const maxCharacters = 1000;

  const currentDomain = window.location.origin;

  if (submitted) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F8F6F4",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          alt="Brand logo"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px",
          }}
        >
          <MuiLink component={RouterLink} to="/" underline="hover">
            <BrandLogo
              sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
            />
          </MuiLink>
        </Box>
        <Fade in={submitted} timeout={700}>
          <Container sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              color="black"
              sx={{
                marginBottom: 2,
                fontWeight: "700",
                fontSize: { xs: "1.4rem", md: "1.8rem" },
                color: "black",
              }}
            >
              All good, thanks! 🫡
            </Typography>
            <MuiLink href="/request" target="_blank" underline="always">
              <Typography
                variant="body1"
                sx={{
                  color: "primary",
                  fontSize: { xs: "0.8rem", md: "1rem" },
                }}
              >
                You can also create your own feedback request.
              </Typography>
            </MuiLink>
          </Container>
        </Fade>
      </Box>
    );
  }

  return (
    <div>
      {questionData && (
        <Helmet>
          <title>Give {questionData.name} feedback anonymously</title>
          <meta
            name="description"
            content={`Provide anonymous feedback to ${questionData.name} through Sincero.`}
          />
          <meta
            property="og:title"
            content={`Give feedback to ${questionData.name}`}
          />
          <meta
            property="og:description"
            content="Provide your honest feedback anonymously and securely through Sincero."
          />
          <meta
            property="og:url"
            content={`${currentDomain}/answer/${requestId}`}
          />
        </Helmet>
      )}

      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F8F6F4",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          alt="Brand logo"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px",
          }}
        >
          <MuiLink component={RouterLink} to="/" underline="hover">
            <BrandLogo
              sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
            />
          </MuiLink>
        </Box>

        <Fade in={true} timeout={700}>
          <Container maxWidth="sm" sx={{ textAlign: "center" }}>
            {loading ? (
              <Typography variant="body1">Loading feedback form...</Typography>
            ) : questionData ? (
              <>
                <Typography sx={{ marginBottom: 2, color: "grey.700" }}>
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    {questionData.name}
                  </Box>{" "}
                  would like to ask:
                </Typography>

                <Typography
                  sx={{
                    marginBottom: 2,
                    fontWeight: "bold",
                    fontSize: { xs: "1.6rem", sm: "1.8rem" },
                  }}
                >
                  {questionData.question}
                </Typography>

                {questionData.need_rating && (
                  <>
                    <Rating
                      name="feedback-rating"
                      value={rating}
                      onChange={(event, newValue) => setRating(newValue)}
                      size="large"
                      sx={{ marginBottom: ratingError ? 1 : 2 }} // Adjust margin if error is shown
                    />
                    {ratingError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ marginBottom: 2 }}
                      >
                        Please provide a rating.
                      </Typography>
                    )}
                  </>
                )}

                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Share your thought"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    required
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      htmlInput: {
                        maxLength: maxCharacters,
                      },
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      marginTop: 1,
                      marginBottom: 2,
                    }}
                  >
                    {answer.length}/{maxCharacters} characters
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: 1.5,
                      borderRadius: "50px",
                      textTransform: "none",
                      width: "100px",
                      backgroundColor: "primary",
                      color: "white",
                    }}
                  >
                    <CheckIcon />
                  </Button>
                  <Typography
                    variant="body2"
                    sx={{ marginTop: 2, color: "grey.700", fontSize: "0.8rem" }}
                  >
                    Your answer is totally anonymous.{" "}
                    <MuiLink href="/" target="_blank" underline="hover">
                      Click here to learn more about our feedback process.
                    </MuiLink>
                  </Typography>
                </form>
              </>
            ) : (
              <Typography variant="body1">Feedback form not found</Typography>
            )}
          </Container>
        </Fade>
      </Box>
    </div>
  );
};

export default BusinessAnswerPage;
