import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

import {
  Typography,
  Button,
  Container,
  Box,
  Tooltip,
  IconButton,
  Fade,
} from "@mui/material";

// Import Clipboard and Check Icons from Material UI
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BrandLogo from "../brandLogo.jsx";

const FusiRequestSuccessPage = () => {
  const { requestId } = useParams();
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [questionData, setQuestionData] = useState(null);

  /*
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("submittedEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);
  */

  useEffect(() => {
    const fetchQuestionData = async () => {
      setLoading(true);
      const docRef = doc(
        db,
        process.env.REACT_APP_FUSI_REQUEST_COLLECTION_NAME,
        requestId
      );

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionData(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
        setLoading(false);
      }
    };

    fetchQuestionData();
  }, [requestId]);

  // Generate the feedback link
  const feedbackLink = `${window.location.origin}/fusi/answer/${requestId}`;

  // Copy feedback link to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(feedbackLink).then(
      () => {
        setCopySuccess(true); // Show success feedback
        setTimeout(() => setCopySuccess(false), 3000); // Reset after 3 seconds
      },
      () => {
        console.error("Failed to copy the link");
      }
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#F8F6F4",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        alt="Brand logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
          width: "40px",
          height: "40px",
        }}
      >
        <Link to="/">
          <BrandLogo
            sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
          />
        </Link>
      </Box>

      <Fade in={true} timeout={700}>
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            color="black"
            sx={{
              marginBottom: 2,
              fontWeight: "700",
              fontSize: { xs: "1.4rem", md: "1.8rem" },
              color: "black",
            }}
          >
            All set!
          </Typography>

          {questionData && (
            <Typography
              variant="body1"
              sx={{ marginBottom: 5, color: "grey.800" }}
            >
              <Box
                component="span"
                sx={{ fontWeight: "bold", color: "primary.main" }}
              >
                Please send the link below to {questionData.respondent_name}
              </Box>
              . You will receive the compatibility report at{" "}
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {questionData.email}
              </Box>{" "}
              once {questionData.respondent_name} has submitted its answer.
            </Typography>
          )}

          <Box
            sx={{
              marginBottom: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip
              title={copySuccess ? "Link copied!" : "Click to copy"}
              placement="top"
            >
              <Typography
                variant="body1"
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  overflowWrap: "anywhere",
                }}
                onClick={copyToClipboard}
              >
                {feedbackLink}
              </Typography>
            </Tooltip>

            {/* Clipboard icon */}
            <Tooltip
              title={copySuccess ? "Link copied!" : "Copy to clipboard"}
              placement="top"
            >
              <IconButton onClick={copyToClipboard} sx={{ marginLeft: 1 }}>
                {copySuccess ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <ContentCopyIcon color="action" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          {/*
          <Button
            variant="contained"
            color="primary"
            sx={{ padding: 2, borderRadius: "50px", textTransform: "none" }}
            href="/fusi"
          >
            I want to f**k another situationship!
          </Button>
          */}
        </Container>
      </Fade>
    </Box>
  );
};

export default FusiRequestSuccessPage;
