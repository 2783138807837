import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // FAQ data array
  const faqData = [
    {
      id: "panel1",
      question: "What is Sincero feedback process for individual?",
      answer: (
        <>
          First, the feedback seeker fills out a{" "}
          <Link href="/request" target="_blank" underline="hover">
            form
          </Link>{" "}
          to ask a question. During this setup, they have the option to choose
          whether they want to view the original feedback or only the summarized
          version. Once the request is created, a unique link is generated that
          can be shared with others. People can then provide feedback
          anonymously through this link. After three days, the feedback seeker
          will receive a summary of all responses.
        </>
      ),
    },
    {
      id: "panel2",
      question: "I am a business owner, how can I use Sincero for my business?",
      answer: (
        <>
          Sincero is perfect for businesses to collect valuable feedback from
          customers, helping you gain insights to improve your products or
          services. We offer additional features like custom domain integration
          for a branded experience and recurring feedback reports to track
          trends over time. For all business inquiries, please{" "}
          <Link
            href="mailto:contact@sincero.io"
            target="_blank"
            underline="hover"
          >
            contact us
          </Link>{" "}
          —we’d be happy to assist you!
        </>
      ),
    },
    {
      id: "panel3",
      question:
        "Can the feedback seeker view the feedback itself or only the summary?",
      answer:
        `The feedback seeker has the flexibility to choose this when setting up the feedback request. ` +
        `If the "Keep original feedback" option is selected, the feedback seeker will have access to the ` +
        `unedited responses as provided. Otherwise, only a summarized version will be shared to ensure brevity and focus. ` +
        `Additionally, feedback givers are informed about this choice during the feedback process, ensuring transparency and confidence in providing honest input.`,
    },
    {
      id: "panel4",
      question: "Are all the feedback anonymous?",
      answer: "Yes.",
    },
    {
      id: "panel5",
      question: "Can I customize the feedback process?",
      answer: (
        <>
          Yes, we offer customization options to tailor the feedback process to
          meet your personal or business needs. Please feel free to{" "}
          <Link
            href="mailto:contact@sincero.io"
            target="_blank"
            underline="hover"
          >
            contact us
          </Link>{" "}
          , and we will work with you to create a feedback solution that fits
          your specific requirements.
        </>
      ),
    },
    {
      id: "panel6",
      question: "Are there any limitations on the number of feedback?",
      answer: (
        <>
          In the free version, feedback seekers can receive up to 100 responses,
          with each individual feedback capped at 1,000 characters. For more
          advanced options and customized solutions, we encourage you to{" "}
          <Link
            href="mailto:contact@sincero.io"
            target="_blank"
            underline="hover"
          >
            contact us
          </Link>{" "}
          to explore tailored plans that fit your needs.
        </>
      ),
    },
  ];

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 6, sm: 8 },
        pb: { xs: 6, sm: 8 },
        pl: { xs: 2, sm: 2 },
        pr: { xs: 2, sm: 2 },
        position: "static",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 2, sm: 4 },
      }}
    >
      <Typography
        component="h2"
        variant="h2"
        fontWeight="600"
        sx={{
          color: "text.primary",
          textAlign: { xs: "left", md: "center" },
          fontSize: { xs: "1.4rem", md: "1.6rem" },
          width: "100%",
        }}
      >
        Frequently asked questions
      </Typography>
      <Box>
        {faqData.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
            sx={{
              borderRadius: 2,
              mb: 1,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              position: "static",
              backgroundColor: "#F8F6F4",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${faq.id}-content`}
              id={`${faq.id}-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
