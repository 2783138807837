import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

import "./App.css";
import HomePage from "./components/homePage.jsx";
import RequestSuccessPage from "./components/no_BS_feedback/requestSuccessPage.jsx";
import AnswerPage from "./components/no_BS_feedback/answerPage.jsx";
import FeedbackRequestPage from "components/no_BS_feedback/feedbackRequestPage.jsx";
import BusinessAnswerPage from "components/no_BS_feedback/businessAnswerPage.jsx";
import FUSIRequestPage from "components/fuck_situationship/fusiRequestPage.jsx";
import FUSIAnswerPage from "components/fuck_situationship/fusiAnswerPage.jsx";
import FusiRequestSuccessPage from "components/fuck_situationship/fusiRequestSuccessPage.jsx";

import theme from "./theme.js";
import { ThemeProvider } from "@mui/material/styles";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Analytics />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/request/success/:requestId"
            element={<RequestSuccessPage />}
          />
          <Route path="/answer/:requestId" element={<AnswerPage />} />
          <Route
            path="business/answer/:requestId"
            element={<BusinessAnswerPage />}
          />
          <Route path="/request" element={<FeedbackRequestPage />} />
          <Route path="/fusi" element={<FUSIRequestPage />} />
          <Route path="fusi/answer/:requestId" element={<FUSIAnswerPage />} />
          <Route
            path="/fusi/success/:requestId"
            element={<FusiRequestSuccessPage />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
