import { React, useState, useEffect, useRef } from "react";
import Header from "./Header.jsx";
import {
  Container,
  Typography,
  Box,
  Fade,
  Button,
  MenuItem,
  Menu,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import KeyFeatures from "./keyFeatures.jsx";
import Footer from "./Footer.jsx";
import FAQ from "./FAQ.jsx";

const bottomImages = [
  "/assets/images/homepage/homePageAsset1.png",
  "/assets/images/homepage/homePageAsset2.png",
  "/assets/images/homepage/homePageAsset3.png",
];

const HomePage = () => {
  const navigate = useNavigate();
  const [zoomIn, setZoomIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const buttonRef = useRef(null);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));
  const imagesToRender = isXs ? [bottomImages[0]] : bottomImages;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setZoomIn(true);
  }, []);

  const useInView = (options) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it's in view
        }
      }, options);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) observer.unobserve(ref.current);
      };
    }, [options]);

    return [ref, isVisible];
  };

  const [featuresRef, featuresInView] = useInView({ threshold: 0.1 });
  const [faqRef, faqInView] = useInView({ threshold: 0.1 });

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#F8F6F4", // Full-width background color
          minHeight: "100vh", // Ensures background extends to full height of the viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8 }}>
          <Header />
          <Box
            id="hero"
            sx={{
              textAlign: "center",
              paddingTop: { xs: 12, sm: 12, md: 14 },
            }}
          >
            <Fade in={zoomIn} timeout={1000}>
              <Typography
                color="black"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "1.5rem", sm: "1.5rem", md: "1.8rem" },
                  marginBottom: 1,
                }}
              >
                Amplify Human Connection Through AI
              </Typography>
            </Fade>

            <Fade in={zoomIn} timeout={1000}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ marginBottom: 2, maxWidth: 600, marginX: "auto" }}
              >
                Experience a new standard in genuine communication. With us,
                every conversation becomes an opportunity to connect on a more
                authentic level.
              </Typography>
            </Fade>

            <Fade in={zoomIn} timeout={1000}>
              <div>
                <Button
                  variant="contained"
                  ref={buttonRef}
                  sx={{
                    padding: "10px 30px",
                    borderRadius: "50px",
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "500",
                    backgroundColor: "primary",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                    marginBottom: { xs: 6, sm: 6, md: 8 },
                  }}
                  onClick={handleMenuOpen}
                >
                  Discover Our Products
                </Button>

                {/* Menu with two features */}
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "15px",
                      backgroundColor: "#F8F6F4",
                    },
                  }}
                  MuiPaper-root
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      navigate("/fusi");
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 600 }}>
                        🤝🏼 f**kSituationship
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Answer daily question for compatibility check
                      </Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      navigate("/request");
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 600 }}>
                        📝 noBSFeedback
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Collect and understand feedback efficiently
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </div>
            </Fade>

            <Fade in={zoomIn} timeout={2500}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  flexWrap: "wrap",
                  marginBottom: 6,
                }}
              >
                {imagesToRender.map((src, idx) => (
                  <Box
                    key={idx}
                    component="img"
                    src={src}
                    alt={`bottom image ${idx}`}
                    sx={{
                      width: { xs: "90%", sm: "60%", md: "30%" },
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: 3,
                      border: "2px solid black",
                      boxShadow: 3,
                    }}
                  />
                ))}
              </Box>
            </Fade>
          </Box>

          <Fade in={featuresInView} timeout={1200}>
            <Box ref={featuresRef}>
              <KeyFeatures />
            </Box>
          </Fade>

          <Fade in={faqInView} timeout={1200}>
            <Box ref={faqRef}>
              <FAQ />
            </Box>
          </Fade>

          <Divider />
          <Footer />
        </Container>
      </Box>
    </div>
  );
};

export default HomePage;
