import { React, useState } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";

import { Link } from "react-router-dom";

const FUSIHowDoesItWork = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
        <Typography
          variant="h5"
          color="black"
          sx={{
            marginBottom: 4,
            fontWeight: "400",
            fontSize: { xs: "0.8rem", md: "0.8rem" },
            color: "grey.700",
          }}
        >
          How does it work?
        </Typography>
      </Link>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#F8F6F4", // Set your desired background color
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "grey.900" }}>
          {"How does it work?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="body1"
              sx={{ color: "grey.900", marginBottom: 2 }}
            >
              1. Answer our thought-provoking daily question and enter the name
              of the person you’d like to assess your compatibility with.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "grey.900", marginBottom: 2 }}
            >
              2. Provide your own name and email address, and we'll instantly
              generate a unique link you can share.
            </Typography>
            <Typography variant="body1" sx={{ color: "grey.900" }}>
              3. Once the other person answers, our AI agent will analyze both
              sets of responses and deliver a detailed report on how closely the
              two of you align on this meaningful topic.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FUSIHowDoesItWork;
